import { useParams } from 'react-router-dom'
import certicates from '../data/certificates.json'

const Certificate = () => {
  const { slug } = useParams()
  const certificate = certicates.find(c => c.slug === slug)

  if (!certificate) return
  
  return (
    <div className='container'>
      <img src={certificate.image} alt='Certificate' />
    </div>
  )
}

export default Certificate